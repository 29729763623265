<template>
  <b-overlay :show="isFetchingStudentStats">
    <b-row>
      <b-col md="12">
        <b-card class="gradient-background student-view-card mb-0">
          <b-row v-if="room"
                 class="px-2 pt-2 m-0 student-view-card-row"
          >
            <!-- Left Side -->
            <b-col
              md="12"
              lg="7"
            >
              <!-- Class Details -->
              <b-row class="top-row">
                <b-col
                  lg="12"
                  md="12"
                  class="col"
                >
                  <span class="h2 text-title new-text mr-2">{{
                    formatClassName(room.name)
                  }}</span>
                  <b-badge
                    v-if="!hideNoOfStudent"
                    pill
                    class="bg-pink py-half pl-2 pr-2 new-text font-100"
                  >
                    <feather-icon icon="UsersIcon" />
                    {{ $t("student-report-module.total-students") }} :
                    {{ studentWithStat.length ? studentWithStat.length : "0" }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="p-t-1">
                <b-col
                  v-if="!hideTeacher"
                  md="12"
                  class="d-flex"
                >
                  <span
                    class="text-dark-blue h5 new-text teachers"
                  >{{ $t("class-module.teachers") }}:</span>
                  <span v-if="room.teacher"
                        class="text-dark-blue h5 new-text"
                  >
                    {{ room.teacher.firstname+' '+room.teacher.lastname }}
                  </span>
                  <!-- if there are no teachers, show request link -->
                  <div v-if="isAStudent && canRequestTeacher">
                    <b-overlay
                      :show="requestProcessing"
                      bg-color="dark"
                    >
                      <b-link
                        class="font-weight-bold ml-1"
                        @click="requestTeacher"
                      >
                        [{{ $t('class-module.request-teacher') }}]
                      </b-link>
                    </b-overlay>
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-if="room.teachers && !hideCoTeacher"
                class="p-t-1"
              >
                <!-- Teachers -->
                <b-col
                  md="12"
                  class="d-flex"
                >
                  <span
                    class="text-dark-blue h5 new-text teachers"
                  >{{ $t("class-module.co-teachers") }}: </span>

                  <!-- if there is teacher, show them -->
                  <div v-if="room.teachers.length">
                    <span
                      v-for="(teacher, index) in ARRAY_UNIQUE_BY_KEY(room.teachers, 'id')"
                      :key="teacher.id"
                    >
                      <span
                        class="text-dark-blue h5 new-text"
                      >
                        {{ teacher.id == self.id ? $t('you') : teacher.firstname + ' ' + teacher.lastname }}
                      </span>
                      <span
                        v-if="index !== room.teachers.length - 1"
                      >,
                      </span>
                    </span>
                  </div>

                  <span
                    v-else
                    class="text-dark-blue h5 new-text"
                  > {{ $t('class-module.no-teachers-assigned') }}</span>
                </b-col>
              </b-row>

              <!-- Owner -->
              <b-row
                v-if="!hideOwner && room.owner && !isAStudent"
                class="p-t-1"
              >
                <b-col md="4">
                  <span
                    class="text-dark-blue h5 new-text"
                  >{{ $t("class-module.class-table.owner") }}:
                    {{
                      room.owner.id == self.id ? $t('you') : room.owner.firstname + " " + room.owner.lastname
                    }}
                  </span>
                </b-col>
              </b-row>

              <!-- School -->
              <b-row
                v-if="room.school && !hideSchool"
                class="p-t-1"
              >
                <b-col md="12">
                  <span
                    class="text-dark-blue h5 new-text"
                  >{{ $t("class-module.class-table.school") }}:
                    {{ room.school.firstname }} {{ room.school.lastname }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="p-t-1">
                <b-col
                  md="12"
                  class="row"
                >
                  <span class="pl-1">
                    <p class="new-text p-0 m-0">
                      {{ $t("class-module.start-date") }}
                    </p>
                    <p class="text-dark-blue">
                      {{ room.created_at ? FORMAT_DATE(room.created_at, 'YYYY-MM-DD') : "N/A" }}
                    </p>
                  </span>
                  <span class="ml-1">
                    <p class="new-text p-0 m-0">
                      {{ $t("class-module.end-date") }}
                    </p>
                    <p class="text-dark-blue">
                      {{ room.completed_at ? FORMAT_DATE(room.completed_at, 'YYYY-MM-DD') : "N/A" }}
                    </p>
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              lg="5"
              md="12"
            >
              <!-- Book Image -->
              <!-- <div class="d-flex justify-content-end">
                  <img
                    class="my-0 d-none d-lg-block"
                    width="180px"
                    src="@/assets/images/icons/new-book.svg"
                  />
                </div> -->
              <!-- Badges Info -->
              <div class="d-none d-lg-block text-right">
                <b-button
                  v-if="isTrailUser"
                  variant="success"
                  role="button"
                  :disabled="isUpdatingToPaid"
                  class="badge-glow mr-2"
                  @click="updateToPaid('full_access')"
                >
                  <b-spinner v-if="isUpdatingToPaid"
                             small
                  />  Upgrade to Full Access
                </b-button>
                <b-button
                  v-if="isTrailUser || isFullAccessUser"
                  variant="success"
                  role="button"
                  :disabled="isUpdatingToPaid"
                  class="badge-glow mr-2"
                  @click="updateToPaid('full_access_tutor')"
                >
                  <b-spinner v-if="isUpdatingToPaid"
                             small
                  />  Upgrade to Tutor Support
                </b-button>
                <feather-icon
                  v-b-tooltip.hover
                  v-b-modal.badges-info
                  :title="$t('labels.badges-info')"
                  icon="InfoIcon"
                  size="22"
                  fill="#ffe699"
                  color="#777"
                  class="info-icon cursor-pointer"
                />
              </div>
              <b-row class="p-0 pr-1">
                <b-col
                  md="5"
                  class="stats-container-left"
                  :style="{
                    'background-image':
                      'url(' +
                      require('@/assets/images/icons/new-book.svg') +
                      ')',
                  }"
                />
                <!-- Class Statistics -->
                <b-col md="7 p-0">
                  <div class="stats-container">
                    <!-- Highest Point -->
                    <div
                      v-if="topScorer && topScorer.totalPoints > 0"
                      class="d-flex my-1 mx-lg-1 justify-content-lg-end"
                    >
                      <div class="class-stats text-lg-right">
                        <h6 class="text-dark-blue new-text title">
                          {{ topScorer.fullname }}
                        </h6>
                        <h6 class="small text-dark-blue font-weight-bold">
                          {{ $t("class-module.highest-points") }} :
                          {{ topScorer.totalPoints }}
                        </h6>
                      </div>
                      <div class="badge-container text-lg-center">
                        <img
                          class="badge-icon"
                          :src="
                            require(`@/assets/images/${badges.highestPointGainerBadge}`)
                          "
                        >
                      </div>
                    </div>
                    <!-- First Achiever -->
                    <div
                      v-if="
                        firstDoingStudent &&
                          firstDoingStudent.totalFirstLessonDone > 0
                      "
                      class="d-flex mx-lg-1 my-1 justify-content-lg-end"
                    >
                      <div class="class-stats text-lg-right">
                        <h6 class="text-dark-blue new-text title">
                          {{ firstDoingStudent.fullname }}
                        </h6>
                        <h6 class="small text-dark-blue font-weight-bold">
                          {{ $t("class-module.lesson-completed") }}
                        </h6>
                      </div>
                      <div class="badge-container text-lg-center px-0">
                        <img
                          class="badge-icon"
                          :src="
                            require(`@/assets/images/${badges.firstLessonAchieverBadge}`)
                          "
                        >
                        <p class="mb-0 text-center">
                          X {{ firstDoingStudent.totalFirstLessonDone }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="isHighLevelUser || isATeacher"
                   cols="12"
                   class="d-flex justify-content-end pb-1 class-switcher"
            >
              <div class="d-flex align-items-center"
                   style="gap:5px;"
              >
                <span><b>{{ $t("class-module.self-study-mode") }}</b></span>
                <b-form-checkbox v-model="selfStudyClassRoom"
                                 variant="success"
                                 switch
                                 value="1"
                                 unchecked-value="0"
                                 @change="updateSelfStudyClassRoom"
                />
                <feather-icon v-b-tooltip.hover.left="$t('class-module.self-study-info')"
                              class="cursor-pointer text-primary"
                              icon="InfoIcon"
                              size="20"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
            >
              <!-- Buttons -->
              <student-table-nav
                :room="room"
                :is-high-level-user="isHighLevelUser"
                :is-a-teacher="isATeacher"
                :is-a-student="isAStudent"
                :is-processing="isProcessing"
                :class-id="classId"
                :has-class-test="!!todayClassTests.length"
                :is-a-class-conductor="isAClassConductor"
                :is-self-study="selfStudyClassRoom==1"
                :hide-class-test-setting="hideClassTest"
                :hide-view-essay="hideViewEssay"
                :hide-show-script="hideShowScript"
                @showClassStats="showClassStats"
                @showGroupReportModal="showGroupReportModal = true"
                @viewClassCalendar="viewClassCalendar(room.id)"
                @showNotification="showNotification"
                @showClassRubrics="showClassRubrics = true"
                @showAttendanceModal="showAttendanceModal = true"
                @showGameSettingsModal="showGameSettingsModal = 'game-setting'"
                @showClassSettings="showGameSettingsModal = 'class-setting'"
                @showInstruction="showClassScript = true"
              />
            </b-col>
            <!-- Right Side -->
            <b-col
              lg="3"
              class="text-right"
            />
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <!-- Right Side -->
      <b-col
        md="12"
        class="filters"
      >
        <b-card
          class="mb-1"
        >
          <b-row>
            <b-col
              md="12"
              class="md-justify-content-between m-0 p-0 d-lg-flex"
            >
              <b-button
                v-if="!hideClassroomListButton"
                class="btn-whitish-gray list-class-btn m-md"
                @click="backToClassRoom()"
              >
                <feather-icon icon="ArrowLeftIcon" />
                {{ $t("class-module.list-of-classes") }}
              </b-button>

              <b-button
                v-if="isHighLevelUser || isAClassTeacher"
                v-b-modal.modal-add-students
                class="btn-pink m-md"
              >
                <feather-icon icon="UserPlusIcon" />
                {{ $t("class-module.add-students") }}
              </b-button>
              <b-button
                v-if="isHighLevelUser || isAClassTeacher"
                class="btn-primary m-md"
                @click="
                  $router.push({
                    name: `${self.usertype}-pair-student`,
                    params: { classId },
                  })
                "
              >
                <feather-icon icon="ShuffleIcon" />
                {{ $t("class-module.pair-students") }}
              </b-button>

              <b-button
                v-if="room.link"
                variant="success"
                class="m-md"
                @click="
                  openClassLink
                "
              >
                <feather-icon icon="ExternalLinkIcon" />
                {{ $t('labels.live-link') }}
              </b-button>
              <v-select
                v-if="!hideStudentCards"
                v-model="filterStudent"
                :options="searchUser"
                :filterable="false"
                :reduce="(fullname) => fullname.id"
                class="abc m-md col-lg-4 col-md-8 col-sm-10"
                label="fullname"
                :placeholder="$t('labels.type-to-search')"
                @search="searchStudent"
              />

              <!-- <b-form-input
                  v-model="searchInput"
                  placeholder="Search"
                  type="text"
                  @input="searchStudent"
                /> -->
            </b-col>
          </b-row>
          <b-tabs v-model="activeTab"
                  class="mt-1"
          >
            <b-tab
              v-for="courseDetail,index in courseByProgress"
              :key="index"
            >
              <template #title>
                {{ capitalizeFirstLetter(courseDetail.title) }}<b-badge
                  class="ml-1 btn-rounded"
                  :variant="index === 0 ? 'light-primary': 'light-success'"
                >
                  {{ courseDetail.data.length }}
                </b-badge>
              </template>
              <template v-if="courseDetail.data.length">
                <span
                  v-for="course in courseDetail.data"
                  :key="course.id"
                >
                  <span v-html="applyAfterCssForCourseProgress(course)" />
                  <b-button
                    :id="`course-btn-${course.course_id}`"
                    class="m-md btn-whitish-gray progress-btn"
                    :disabled="isAParent"
                    @click.stop="viewLessons(course.course_id, classId)"
                  >
                    {{ course.course_name }}
                    <b-badge
                      variant="light-primary"
                      class="rounded align-middle"
                    >{{ course.total_homework }}
                      <!-- <sub>HW</sub> -->
                    </b-badge>
                  </b-button>
                </span>
              </template>
              <template v-else>
                <h6 class="my-2">
                  {{ $t('courses-module.no-courses-available') }}
                </h6>
              </template>
            </b-tab>
            <b-tab v-if="isAStudent && courseByProgress.length">
              <template #title>
                {{ $t('courses-module.follow-ups') }} <b-badge
                  class="ml-1 btn-rounded"
                  :variant="'light-success'"
                > {{ followUpCourses.length }} </b-badge>
              </template>
              <template v-if="followUpCourses.length">
                <span
                  v-for="followUpCourse in followUpCourses"
                  :key="followUpCourse.id"
                >
                  <b-button
                    :id="`course-btn-${followUpCourse.course_id}`"
                    class="m-md btn-whitish-gray progress-btn"
                    :disabled="isAParent"
                    @click.stop="viewLessons(followUpCourse.course_id, classId, 'follow_up')"
                  >
                    {{ followUpCourse.course_name }}
                  </b-button>
                </span>
              </template>
              <template v-else>
                <h6 class="my-2">
                  {{ $t('courses-module.no-follow-up') }}
                </h6>
              </template>
            </b-tab>
            <b-tab v-if="hasVocabLesson"
                   title="Vocab Lesson"
            >
              <b-button variant="outline-primary"
                        @click="$router.push({
                          name: `${self.usertype}-vocab-problem`,
                          params: {
                            classId: classId,
                          }
                        })"
              >
                Open Vocab Lesson <feather-icon icon="ExternalLinkIcon" />
              </b-button>
            </b-tab>
          </b-tabs>
          <div v-if="courses.length > 0"
               class="course-progress-bar"
               style="position: absolute; right: 0"
          >
            <div class="vertical-progress">
              <div style="margin-right: -120px"
                   class="bar"
              >
                <b-progress
                  style="width: 170px;"
                  :max="100"
                >
                  <b-progress-bar :variant="courseProgress"
                                  :value="overAllCourseCompletion"
                  >
                    <strong>{{ overAllCourseCompletion }} </strong>
                  </b-progress-bar>
                </b-progress>
                <span>{{ $t('class-module.course-completion') }}</span>
              </div>
            </div>
          </div>
          <!-- <b-row class="pt-2 d-flex">
            <template v-for="courseDetail,index in courseByProgress">
              <b-col
                v-if="courseDetail.length > 0"
                :key="index"
              >
                <h3 class="ml-1">
                  {{ capitalizeFirstLetter(index) }} Courses
                </h3>
                <span
                  v-for="course in courseDetail"
                  :key="course.id"
                >
                  <b-button
                    class="m-md btn-whitish-gray"
                    :disabled="isAParent"
                    @click.stop="viewLessons(course.id, classId)"
                  >
                    {{ course.name }}
                    <b-badge
                      variant="light-primary"
                      class="rounded align-middle"
                    >{{ course.lesson_count }}</b-badge>
                  </b-button>
                </span>
              </b-col>
            </template>
          </b-row> -->
        </b-card>
      </b-col>

      <div
        v-if="!isProcessing && room.students && room.students.length === 0"
        class="w-100"
      >
        <b-col class="text-center text">
          <b-card>
            <span class="h4 text-dark-blue">{{
              $t("messages.no-data-available")
            }}</span>
          </b-card>
        </b-col>
      </div>
    </b-row>
    <b-row class="pt-1">
      <b-col md="12">
        <template
          v-if="filteredStudentsLists.length > 0"
        >
          <vue-perfect-scrollbar ref="scrollContainer"
                                 :settings="perfectScrollbarSettings"
                                 class="scroll-content"
                                 @scroll="handleStudentListScroll"
          >
            <b-row
              class="w-100"
            >
              <b-col
                v-for="(student, index) in filteredStudentsLists"
                :key="student.id"
                md="6"
                :xl="notResponsiveWindow ? 4 : 3"
                lg="6"
                class="pr-0"
              >
                <b-card class="student-details-card pt-0">
                  <b-row class="badge-row">
                    <div class="col-12 p-0 m-0 text-right">
                      <template v-if="!hideBadge">
                        <img
                          v-if="index === 0 && student.totalPoints > 0"
                          class="top-badge"
                          :src="
                            require(`@/assets/images/${badges.highestPointGainerBadge}`)
                          "
                          title="Highest Points Gainer"
                        >
                        <!-- :style="`right:${topScorer && topScorer.length>0 && student.id==topScorer[0].user.id ? '35px' : '8px'}`" -->
                        <img
                          v-if="student.totalFirstLessonDone > 0"
                          class="first-badge ml-0"
                          :src="
                            require(`@/assets/images/${badges.firstLessonAchieverBadge}`)
                          "
                          title="First Lesson Acheiver"
                        >
                        <span
                          v-if="
                            student.totalFirstLessonDone &&
                              student.totalFirstLessonDone > 1
                          "
                        >X{{ student.totalFirstLessonDone }}
                        </span>
                        <!-- :style="`right:${getRight(student.id)}`" -->
                        <img
                          v-if="additionalResult[index]"
                          class="first-badge"
                          :src="
                            require(`@/assets/images/${badges.extraOrdinaryBadge}`)
                          "
                          title="Extraordinary"
                        >
                      </template>
                      <!-- <span
                      v-if="student.lang_level === 'none'"
                      v-b-tooltip.hover="$t('labels.language-level')"
                    >
                      <b>E</b>
                    </span> -->
                      <span
                        v-if="student.lang_level !== 'none' && student.lang_level && !hideLanguageLevel"
                        v-b-tooltip.hover="$t('labels.language-level')"
                      >
                        <span
                          class="badge text-white"
                          :style="{
                            background: getColorForLevel(student.lang_level),
                          }"
                        >
                          {{ student.lang_level }}</span>
                      </span>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col
                      md="12"
                      xl="5"
                      lg="12"
                      :class="!isAStudent ? 'cursor-pointer' : ''"
                      @click="
                        !isAStudent
                          ? $router.push({
                            name: `student-dashboard-for-${self.usertype}`,
                            params: { id: student.id },
                            query: { from: 'classroom' }
                          })
                          : ''
                      "
                    >
                      <b-avatar
                        v-if="student.avatar"
                        badge
                        square
                        :badge-variant="
                          student.isOnline ? 'success' : 'secondary'
                        "
                        badge-offset="-0.1em"
                        :src="student.avatar"
                        size="100px"
                      />
                      <div
                        v-else
                        style="position: relative; width: 100px"
                      >
                        <avatar
                          :username="student.fullname"
                          :size="100"
                          :rounded="false"
                        />
                        <div
                          :class="`avatar-status-indicator avatar-${
                            student.isOnline ? 'online' : 'offline'
                          }`"
                        />
                      </div>
                      <div
                        v-if="roles.conductor === student.role"
                        v-b-tooltip.hover="
                          $t('class-module.student-role.conductor')
                        "
                        class="ribbon"
                      >
                        <span>
                          {{
                            $t("class-module.student-role.conductor-half-name")
                          }}
                        </span>
                      </div>
                    </b-col>
                    <b-col
                      md="12"
                      lg="12"
                      xl="7"
                      class="p-sm-n-1 pt-1 pb-0"
                    >
                      <h4
                        v-b-tooltip.hover
                        :class="!isAStudent ? 'cursor-pointer' : ''"
                        class="text-truncate"
                        :title="student.fullname"
                        @click="
                          (!isAStudent && !isAParent) || isAParentChild(student.id)
                            ? $router.push({
                              name: `student-dashboard-for-${self.usertype}`,
                              params: { id: student.id },
                              query: { from: 'classroom' }
                            })
                            : ''
                        "
                      >
                        {{ student.fullname }}
                      </h4>
                      <div @click="canViewCouseProgress(student.id) && selectCourseDetails(student.id)">
                        <h5 class="cursor-pointer">
                          <!-- {{ Math.round(student.totalProcess * 100) / 100 }}%
                      <span
                        v-if="student.extraProgress"
                        class="text-success cursor-pointer"
                        title="Additional Lesson Completed"
                      >(+
                        {{ Math.round(student.extraProgress * 100) / 100 }}%)
                      </span> -->
                          <span class="float-right"
                                style="margin-top: -4px; margin-left: 5px "
                          >
                            <feather-icon v-b-tooltip.hover.top="$t('class-module.progressbar-info')"
                                          icon="InfoIcon"
                                          class="text-success"
                            />
                          </span>
                        </h5>
                        <template v-if="singleDoubleCourseCompletionBar === 0">
                          <b-progress
                            :max="100"
                          >
                            <b-progress-bar v-if="+student.currentProgress != +student.totalProcess && hideCurrentProgress===0"
                                            v-b-tooltip.hover.top="`${$t('current-progress')} ${student.currentProgress} `"
                                            :value="student.currentProgress"
                                            class="progress-bar-warning"
                                            :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                                            variant="warning"
                                            style="cursor: pointer"
                            >
                              <strong>{{ `${student.currentProgress} %` }} </strong>
                            </b-progress-bar>
                            <b-progress-bar
                              v-if="!hideFullCourseProgress"
                              v-b-tooltip.hover.top="`${$t('total-progress')} ${student.totalProcess}%`"
                              :value="student.totalProcess"
                              class="progress-bar-success"
                              :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                              variant="success"
                              style="cursor: pointer"
                            >
                              <strong>{{ `${student.totalProcess}%` }} </strong>
                            </b-progress-bar>
                          </b-progress>
                          <p
                            :style="{
                              cursor: canViewCouseProgress(student.id) && 'pointer',
                            }"
                            class="pb-0 mb-0 text-muted italic"
                            @click="selectCourseDetails(student.id)"
                          >
                            {{ $t("class-module.course-completion") }}
                          </p>
                        </template>
                        <template v-else>
                          <b-progress
                            :max="100"
                          >
                            <b-progress-bar
                              v-if="+student.currentProgress != +student.totalProcess && hideCurrentProgress===0"
                              v-b-tooltip.hover.top="`${$t('current-progress')} ${student.currentProgress} `"
                              :value="student.currentProgress"
                              class="progress-bar-warning"
                              :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                              variant="warning"
                              style="cursor: pointer;"
                            >
                              <strong>{{ `${student.currentProgress} %` }} </strong>
                            </b-progress-bar>

                          </b-progress>
                          <p
                            :style="{
                              cursor: canViewCouseProgress(student.id) && 'pointer',
                            }"
                            class="pb-0 mb-0 text-muted italic"
                            @click="selectCourseDetails(student.id)"
                          >
                            {{ $t('current-progress') }}
                          </p>
                          <b-progress
                            :max="100"
                          >
                            <b-progress-bar
                              v-if="!hideFullCourseProgress"
                              v-b-tooltip.hover.top="`${$t('total-progress')} ${student.totalProcess}%`"
                              :value="student.totalProcess"
                              class="progress-bar-success"
                              :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                              variant="success"
                              style="cursor: pointer;"
                            >
                              <strong>{{ `${student.totalProcess}%` }} </strong>
                            </b-progress-bar>
                          </b-progress>
                          <p
                            :style="{
                              cursor: canViewCouseProgress(student.id) && 'pointer',
                            }"
                            class="pb-0 mb-0 text-muted italic"
                            @click="selectCourseDetails(student.id)"
                          >
                            {{ $t('total-progress') }}
                          </p>
                        </template>
                      </div>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col class="mb-1">
                      <div class="d-flex justify-content-between">
                        <div>
                          <h5
                            v-if="!hidePoint && !hideStudentPoint"
                            :class="
                              ((isAStudent && student.id === self.id) ||
                                !isAStudent) && !hideStudentPointDetail
                                ? 'cursor-pointer'
                                : ''
                            "
                            @click="(!hideStudentPointDetail) ? showRewardsModal(student.id) : null"
                          >
                            <feather-icon
                              :icon="pointIcon"
                              fill="#FFC107"
                              size="20"
                            />
                            {{ student.totalPoints ? student.totalPoints : "0" }}
                            {{ $t("labels.points") }}
                            <feather-icon v-b-tooltip.hover.top="$t('class-module.point-info')"
                                          icon="InfoIcon"
                                          class="text-success"
                            />
                          </h5>
                        </div>
                        <template v-if="student.hasPairs">
                          <div v-if="isHighLevelUser || isATeacher">
                            <h5
                              role="button"
                              @click="showMyPair = student.id"
                            >
                              <feather-icon icon="UsersIcon" /> Pairs
                            </h5>
                          </div>
                          <div
                            v-else-if="isAStudent && self.id == student.id"
                            role="button"
                            @click="showMyPair = student.id"
                          >
                            <h5><feather-icon icon="UsersIcon" /> My Pairs</h5>
                          </div>
                        </template>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row :class="addMarginBottom(student.id)">
                    <b-col
                      md="10"
                      class="d-flex"
                    >
                      <feather-icon
                        v-if="(isATeacher || isHighLevelUser || student.id == self.id || isAParentChild(student.id)) && hideSkillChart ===0 && hideStudentSkilChart === 0"
                        v-b-tooltip.hover
                        :title="$t('class-module.show-skill-chart-title')"
                        icon="BarChart2Icon"
                        size="35"
                        color="purple"
                        class="action-icon"
                        @click.stop="studentIdToShowSkill = student.id"
                      />
                      <!-- </b-button> -->
                      <template
                        v-if="
                          isATeacher ||
                            student.id == self.id ||
                            isHighLevelUser ||
                            isAParentChild(student.id)
                        "
                      >
                        <!-- <b-button
                      size="sm"
                      class="btn-rounded btn-whitish-gray p-1"
                      :title="$t('student-report-module.view-student-diary')"
                      @click.stop="showAttendance(student)"
                    > -->
                        <feather-icon
                          v-if="hideStudentDiary === 0"
                          v-b-tooltip.hover
                          icon="ClockIcon"
                          size="35"
                          color="blue"
                          :title="$t('student-report-module.view-student-diary')"
                          class="action-icon"
                          @click.stop="showAttendance(student)"
                        />
                        <!-- </b-button> -->
                        <!-- <b-button
                      size="sm"
                      class="btn-rounded btn-whitish-gray p-1 ml-1"
                      :title="
                        $t('student-report-module.view-student-rubrics')
                      "
                      @click.stop="showRubric(student)"
                    > -->
                        <feather-icon
                          v-if="hideStudentRubrics === 0"
                          v-b-tooltip.hover
                          icon="GridIcon"
                          size="35"
                          color="green"
                          :title="
                            $t('student-report-module.view-student-rubrics')
                          "
                          class="action-icon"
                          @click.stop="showRubric(student)"
                        />
                        <!-- </b-button> -->
                        <!-- <b-button
                      size="sm"
                      class="btn-rounded btn-whitish-gray p-1 ml-1"
                      :title="$t('student-report-module.view-student-notes')"
                      @click.stop="studentIdToSeeNotes = student.id"
                    > -->
                        <feather-icon
                          v-if="hideStudentNotes === 0"
                          v-b-tooltip.hover
                          icon="ClipboardIcon"
                          size="35"
                          color="orange"
                          :title="$t('student-report-module.view-student-notes')"
                          class="action-icon"
                          @click.stop="studentIdToSeeNotes = student.id"
                        />
                        <!-- </b-button> -->
                        <!-- <b-button
                      size="sm"
                      class="btn-rounded btn-whitish-gray p-1 ml-1"
                      :title="$t('student-report-module.view-student-notes')"
                      @click.stop="openClassroomReport(student.id)"
                    > -->
                        <feather-icon
                          v-if="hideStudentIndividualReport === 0"
                          v-b-tooltip.hover
                          icon="FileTextIcon"
                          size="35"
                          color="olive"
                          :title="$t('student-report-module.individual-report')"
                          class="action-icon"
                          @click.stop="openClassroomReport(student.id)"
                        />
                        <!-- </b-button> -->
                      </template>
                    </b-col>
                    <b-col md="1">
                      <student-more-option
                        v-if="isATeacher || isHighLevelUser"
                        :student="student"
                        :class-id="classId"
                        :room="room"
                        :is-high-level-user="isATeacher || isHighLevelUser"
                        @refetchStudent="getClassRoomStat"
                        @deleteStudent="showDeleteModalForStudent(student.id)"
                        @moveStudent="showMoveStudentModal(student)"
                        @addLiveLink="studentToAddLink = student"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </template>
        <template v-else>
          <b-row>
            <b-col>
              <b-card class="student-details-card p-2">
                {{ $t('no-student-added-in-class') }}
              </b-card>
            </b-col>
          </b-row>
        </template>

      </b-col>
    </b-row>
    <student-rubric-modal
      :student="viewingRubricsOfStudent"
      :show="viewingRubricsOfStudent === null"
      :class-id="room ? room.id : null"
      :rubrics="activeClassRubrics"
      :is-teacher="isAClassTeacher"
      @close="viewingRubricsOfStudent = null"
    />
    <delete-modal
      name="modal-delete-student"
      :on-delete="removeStudentFromAClass"
      :title="$t('class-module.delete-student-title')"
      :sub-title="$t('class-module.delete-student-question')"
      :is-processing-delete="isProcessingDelete"
      :show="studentBeingDeleted != null"
      @close="studentBeingDeleted = null"
    />
    <add-students
      :students="students.data || []"
      :last_page="students.last_page || 1"
      :class-id="classId"
      @close="()=>{searchStudentInput=' '; getStudentsForClass()}"
      @fetchMoreStudents="fetchMoreStudents"
      @searchStudents="searchStudents"
      @onStudentsAdded="getClassRoomStat()"
    />
    <!-- <course-completion-details
      :toggle="courseDetailsToggle"
      :rows="selectedCourseDetails"
      :class-id="Number(classId)"
      :is-processing="isProcessingIndividual"
      :selected-student-id="selectedStudentId"
      @courseCompletionModalClosed="courseDetailsToggle = false"
    /> -->
    <course-completion-details
      :toggle="courseDetailsToggle"
      :class-id="Number(classId)"
      :selected-student-id="selectedStudentId"
      @courseCompletionModalClosed="courseDetailsToggle = false"
    />
    <class-rubric-modal
      :show="showClassRubrics"
      :classe="room"
      :rubrics="activeClassRubrics"
      @close="showClassRubrics = false"
      @refreshRubrics="getClassRubrics"
    />

    <!-- Award Stat Group Modal -->
    <award-stats-group
      modal-id="classroom-rewards"
      item="points"
      :student-id="studentId"
      :class-id="parseInt(classId, 10)"
    />

    <!-- Badges Info Model -->
    <badges-info-modal
      v-if="!hideBadgesInfo"
      :badges-icons="badges"
    />
    <skill-preview
      v-if="!!studentIdToShowSkill"
      :show="true"
      :class-room="room"
      :student-id="Number(studentIdToShowSkill)"
      :student-info="
        studentWithStat.find((student) => student.id == studentIdToShowSkill)
      "
      :hide-language-summary-chart="hideLanguageSummaryChart"
      :hide-skill-summary-chart="hideSkillSummaryChart"
      :hide-student-role-chart="hideStudentRoleChart"
      :hide-student-note-chart="hideStudentNoteChart"
      :hide-student-game-report="hideStudentGameReport"
      @close="studentIdToShowSkill = null"
    />
    <attendance
      :show="showAttendanceModal"
      :class-id="Number(classId)"
      :students="studentWithStat"
      @close="showAttendanceModal = false"
    />
    <my-pairs
      :show="!!showMyPair"
      :class-id="parseInt(classId)"
      :student-id="typeof showMyPair === 'number' ? showMyPair : 0"
      @close="showMyPair = false"
    />
    <my-pairs
      :show="!!studentIdToSeeNotes"
      :class-id="parseInt(classId)"
      :only-notes="true"
      :student-id="typeof studentIdToSeeNotes === 'number' ? studentIdToSeeNotes : 0"
      @close="studentIdToSeeNotes = false"
      @closeNote="studentIdToSeeNotes = false"
    />

    <!-- Move Student Modal -->
    <move-student-modal
      :student="studentBeingMoved"
      :current-room="currentRoom"
      @onStudentMoved="getClassRoomStat"
    />

    <!-- Game Settings Modal -->
    <game-settings-modal
      :show="!!showGameSettingsModal"
      :self="self"
      @close="showGameSettingsModal = false"
    />

    <class-test-alerts
      :show="showTestModal"
      :class-id="Number(classId)"
      :test-lists="todayClassTests"
    />
    <class-script v-if="showClassScript"
                  :show="true"
                  :class-id="Number(classId)"
                  :is-high-level-user="isHighLevelUser || isATeacher"
                  @close="showClassScript = false"
    />
    <reservation-schedule v-if="showSchedules"
                          :class-id="classId"
                          @close="showSchedules = false"
    />
    <b-modal
      :visible="!!redirectingLink"
      content-class="shadow"
      title="Opening"
      no-fade
      ok-only
      ok-title="Accept"
      hide-footer
      @hide="redirectingLink = null"
      @close="redirectingLink = null"
    >
      <b-overlay
        show
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        Redirecting to {{ redirectingLink }}
      </b-overlay>
    </b-modal>
    <b-modal v-if="!!studentToAddLink"
             :visible="true"
             title="Add Live Classroom Link For Individual Student"
             @hide="studentToAddLink = null;"
             @close="studentToAddLink = null;"
    >
      <b-form-group>
        <b-form-input v-model="studentToAddLink.live_link"
                      placeholder="Paste Live Classroom Link Here"
        />
        <p class="text-muted pt-2"><i>
          When students need a different classroom, use this link to join a live session with the teacher, tailored to specific needs. It provides personalized support and direct interaction for effective learning.
        </i></p>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="primary"
                  :disabled="!!isProcessing"
                  @click="updateStudentLiveLink"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BBadge,
  BCol,
  BRow,
  VBTooltip,
  BProgress,
  BProgressBar,
  BSpinner,
  BAvatar,
  BLink,
  BOverlay,
  BTabs,
  BTab,
  BFormCheckbox,
  BModal,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Avatar from 'vue-avatar'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

import { getUserData, isHighLevelUser, isAParentChild } from '@/auth/utils'
import i18n from '@/libs/i18n'
import {
  USER_TYPE_SCHOOL,
  USER_TYPE_STUDENT,
  USER_TYPE_SUPER,
  USER_TYPE_TEACHER,
  USER_TYPE_PARENT,
} from '@/const/userType'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import AddStudents from '@/views/common/class/components/AddStudents.vue'
import StudentRubricModal from '@/views/common/class/components/rubric/components/StudentRubricModal.vue'
import useApollo from '@/@core/graphql/useApollo'
import { getLevelFromScore, getColorForLevel } from '@/const/langLevel'
import {
  HIGHEST_POINTS_GAINER_BADGE,
  FIRST_LESSON_ACHEIVER_BADGE,
  EXTRAORDINARY_BADGE,
  POINT_ICON,
} from '@/const/badges'
import fonts from '@/const/fonts'
import ClassRubricModal from '@/views/common/class/components/rubric/components/ClassRubricModal.vue'
import AwardStatsGroup from '@/views/common/dashboard/components/AwardStatsGroup.vue'
import { CONDUCTOR } from '@/const/studentRoles'
import { mapGetters } from 'vuex'
import { engagementSettings, isVisible } from '@/utils/visibilitySettings'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import BadgesInfoModal from './BadgesInfoModal.vue'
import CourseCompletionDetails from './CourseCompletionDetails.vue'
import SkillPreview from './SkillPreview.vue'
import StudentMoreOption from './StudentMoreOptions.vue'
import Attendance from './Attendance.vue'
import MyPairs from './MyPairs.vue'
import MoveStudentModal from './MoveStudentModal.vue'
import StudentTableNav from './StudentTableNav.vue'
import GameSettingsModal from './GameSettingsModal.vue'
import ClassTestAlerts from './ClassTestAlerts.vue'
import ClassScript from './class-script/index.vue'
import ReservationSchedule from './schedules/reservation-schedule.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCol,
    BRow,
    BProgress,
    BSpinner,
    BLink,
    BOverlay,
    BModal,
    StudentRubricModal,
    Avatar,
    DeleteModal,
    AddStudents,
    vSelect,
    BAvatar,
    CourseCompletionDetails,
    ClassRubricModal,
    AwardStatsGroup,
    BadgesInfoModal,
    SkillPreview,
    StudentMoreOption,
    Attendance,
    MyPairs,
    MoveStudentModal,
    StudentTableNav,
    GameSettingsModal,
    ClassTestAlerts,
    ClassScript,
    BTabs,
    BTab,
    BProgressBar,
    BFormCheckbox,
    ReservationSchedule,
    BFormGroup,
    BFormInput,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columnsForStudentsTable: [
        { key: 'fullname', label: i18n.tc('class-module.student-table.name') },
        {
          key: 'action',
          label: i18n.tc('class-module.student-table.actions'),
          thStyle: { minWidth: '100px' },
        },
      ],
      self: getUserData(),
      activeClass: [],
      students: { data: [] },
      isProcessing: false,
      isProcessingDelete: false,
      viewingRubricsOfStudent: null,
      activeClassRubrics: [],
      studentBeingDeleted: null,
      searchInput: null,
      filterStudent: null,
      fetchingTimeout: null,
      room: {},
      searchUser: [],
      lessonCompletor: null,
      additionalResult: {},
      badges: {
        highestPointGainerBadge: HIGHEST_POINTS_GAINER_BADGE,
        firstLessonAchieverBadge: FIRST_LESSON_ACHEIVER_BADGE,
        extraOrdinaryBadge: EXTRAORDINARY_BADGE,
      },
      pointIcon: POINT_ICON,
      cefrRate: {},
      courseDetailsToggle: false,
      selectedStudentId: null,
      isProcessingIndividual: false,
      courses: [],
      showClassRubrics: false,
      studentWithStat: [],
      studentId: null,
      studentIdToShowSkill: null,
      showAttendanceModal: false,
      roles: {
        conductor: CONDUCTOR,
      },
      showMyPair: false,
      studentBeingMoved: null,
      filteredStudentsLists: [],
      showStudentScoreBoard: false,
      showGroupReportModal: false,
      requestProcessing: false,
      studentIdToSeeNotes: null,
      courseByProgress: [],
      showGameSettingsModal: false,
      todayClassTests: [],
      isAParentChild,
      showTestModal: false,
      showClassScript: false,
      page: 1,
      searchStudentInput: '',
      selfStudyClassRoom: 0,
      overAllCourseCompletion: 0.0,
      showSchedules: false,
      isUpdatingToPaid: false,
      redirectingLink: null,
      studentToAddLink: null,
      followUpCourses: [],
      activeTab: 0,
      hideFullCourseProgress: 0,
      hideCurrentProgress: 0,
      hideSkillChart: 0,
      hidePoint: 0,
      hideLanguageLevel: 0,
      hideBadge: 0,
      hideBadgesInfo: 0,
      hideStudentSkilChart: 0,
      hideStudentDiary: 0,
      hideStudentRubrics: 0,
      hideStudentNotes: 0,
      hideStudentIndividualReport: 0,
      singleDoubleCourseCompletionBar: 0,
      defaultIndividualReport: null,
      hideStudentCards: 0,
      hideStudentPoint: 0,
      hideStudentPointDetail: 0,
      hideTeacher: 0,
      hideCoTeacher: 0,
      hideSchool: 0,
      hideOwner: 0,
      hideClassroomListButton: 0,
      hideClassTest: 0,
      hideViewEssay: 0,
      hideShowScript: 0,

      hideLanguageSummaryChart: 0,
      hideSkillSummaryChart: 0,
      hideStudentRoleChart: 0,
      hideStudentNoteChart: 0,
      hideStudentGameReport: 0,
      hasVocabLesson: false,
      isFetchingStudentStats: false,
      studentCurrentPage: 1,
      studentLimit: 30,
      totalStudents: 0,
      studentLastPage: null,
      uniquePair: [],
      perfectScrollbarSettings: {
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    topScorer() {
      return this.studentWithStat[0] ?? null
    },
    isTrailUser() {
      return this.self.user_subscription_type === 'external_trial'
    },
    isFullAccessUser() {
      return this.self.user_subscription_type === 'external_full_access'
    },
    isFullAccessWithTutorUser() {
      return this.self.user_subscription_type === 'external_full_access_tutor' || !this.self.user_subscription_type
    },
    canRequestTeacher() {
      return this.isFullAccessWithTutorUser && ((!this.room.teacher || this.room.teacher.lastname.includes('default')) && this.room.reservation_id)
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAClassTeacher() {
      const teachers = this.room?.teachers?.map(teacher => Number(teacher.id)) || []
      return this.room && this.room.teachers
        ? teachers.includes(Number(this.self.id))
        : false
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isAnAdmin() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    classId() {
      return this.$route.params.id
    },
    isAClassConductor() {
      const conductor = this.studentWithStat.find(stat => stat.conductor === 1 || stat.role === this.roles.conductor)
      return conductor && Number(this.self.id) === conductor.id
    },
    notResponsiveWindow() {
      return window.innerWidth >= 1280 && window.innerWidth < 1600
    },
    getStudentsByPointOrder() {
      const rooms = this.room.students
      if (!rooms) return []
      // rooms.sort((a, b) => b.loaders[0]?.points - a.loaders[0]?.points)
      rooms.sort((a, b) => {
        const previousOrder = a.loaders.reduce((p, n) => p + n.points, 0)
        const currentOrder = b.loaders.reduce((p, n) => p + n.points, 0)
        return currentOrder - previousOrder
      })
      return rooms
    },
    isHighLevelUser() {
      return (
        isHighLevelUser()
        || Number(this.room?.owner?.id) === Number(this.self.id)
      )
    },
    firstDoingStudent() {
      const studentStat = [...this.studentWithStat]
      studentStat.sort(
        (a, b) => b.totalFirstLessonDone - a.totalFirstLessonDone,
      )
      return studentStat[0] ?? null
    },
    currentRoom() {
      return {
        id: this.room.id,
        name: this.room.name,
      }
    },
    courseProgress() {
      const value = this.overAllCourseCompletion
      if (value >= 25.0 && value <= 50.0) { return 'warning' }
      if (value > 50 && value <= 75.0) {
        return 'primary'
      }
      if (value > 75.0) {
        return 'success'
      }
      return 'danger'
    },
    noOfStudentSettings() {
      return {
        distNoOfStudentInfo: this.getDistrictSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
        schoolNoOfStudentInfo: this.getSchoolSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
      }
    },
    hideNoOfStudent() {
      return this.isAStudent && (this.noOfStudentSettings?.distNoOfStudentInfo === '1' || this.noOfStudentSettings?.schoolNoOfStudentInfo === '1')
    },
    inProgressCompletedTabVisbile() {
      const schoolSettings = this.$store.state.appConfig?.schoolInfo?.user_meta
      const districtSettings = this.$store.state.appConfig?.districtInfo?.user_meta

      return isVisible('in_progress_completed', schoolSettings, districtSettings)
    },
  },
  watch: {
    showClassRubrics(value) {
      if (value && !this.activeClassRubrics) {
        this.getClassRubrics()
      }
    },
    filterStudent(newValue) {
      if (!newValue) {
        this.filteredStudentsLists = [...this.studentWithStat]
        return
      }
      this.filteredStudentsLists = this.studentWithStat.filter(student => Number(student.id) === Number(newValue))
      // this.getClassRoomStat()
    },
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_SHOW_AVATAR', false)
    this.getStudentsOfClassRooms()
    // this.getClassRubrics()
    this.searchStudent('')
    this.getClassRoomStat()
    // this.getClassRoomCoursesCount()
    if (this.isAStudent) {
      this.checkAndGetClassTests()
    }
    this.getClassRoomSettings()
  },
  methods: {
    updateStudentLiveLink() {
      this.isProcessing = true
      useJwt.updateStudentLiveLink(this.classId, this.studentToAddLink.id, {
        live_link: this.studentToAddLink.live_link,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getClassRoomStat()
        this.studentToAddLink = null
      }).finally(() => {
        this.isProcessing = false
      })
    },
    formatClassName(name) {
      return name?.split('#')[0]
    },
    backToClassRoom() {
      if (window.PREVIOUS_ROUTE_PATH && window.PREVIOUS_ROUTE_PATH.includes('?page')) {
        this.GO_BACK()
      } else {
        this.$router.push({ name: `${this.AUTH_USER().usertype}-classes` })
      }
    },
    openSSOLink(link) {
      const ssoProvider = link.split(process.env.VUE_APP_SSO_PREFIX)[1]
      useJwt.getSsoLink(ssoProvider).then(response => {
        window.open(response.data.data, '_blank')
        this.redirectingLink = null
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    openClassLink() {
      const { link } = this.room
      const { classId } = this
      this.redirectingLink = link
      useJwt.updateStudentAttendanceWithLiveLink({
        classroom_id: classId,
      }).then(response => {
        this.showSuccessMessage(response)
        if (link.startsWith(process.env.VUE_APP_SSO_PREFIX)) {
          this.openSSOLink(link)
        } else {
          window.open(link, '_blank')
          this.redirectingLink = null
        }
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    openClassroomReport(studentId) {
      this.$router.push({ name: `${this.AUTH_USER().usertype}-individual-report`, params: { studentId, defaultReport: this.defaultIndividualReport, schoolId: this.room.school.id }, query: { classroomId: this.classId } })
    },
    checkAndGetClassTests() {
      useJwt.checkAndGetClassTests(this.classId).then(response => {
        this.todayClassTests = response.data.data.class_tests
        this.showTestModal = !!this.todayClassTests.length
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getClassRoomCoursesCount() {
      useJwt
        .getClassRoomCourseCount(this.classId)
        .then(response => {
          const { data } = response.data
          this.courses = data.courses
          const lessonCompleted = data.loaders_completed
          const courseByProgress = {
            pending: [],
            completed: [],
          }
          this.courses.forEach(course => {
            if (course.lessons?.every(lesson => lessonCompleted.includes(lesson))) {
              courseByProgress.completed.push(course)
            } else {
              courseByProgress.pending.push(course)
            }
          })
          this.courseByProgress = [
            {
              title: 'Courses',
              data: courseByProgress.pending,
            },
            {
              title: 'Completed',
              data: courseByProgress.completed,
            },
          ]
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    canViewCouseProgress(id) {
      return !this.isAStudent || this.self.id === Number(id)
    },
    selectCourseDetails(studentId) {
      if (!this.canViewCouseProgress(studentId)) {
        return
      }
      this.courseDetailsToggle = true
      this.isProcessingIndividual = true
      this.selectedStudentId = studentId
    },
    getRandomVariant() {
      const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
      return a[Math.floor(a.length * Math.random())]
    },
    getFilteredStudent() {
      this.isProcessing = true
      // this.getStudentsOfClassRooms()
    },
    getStudentClassCefr() {
      const { students } = this.room
      if (!students) return
      students.forEach((student, index) => {
        let cefr = 0
        const { loaders } = student
        loaders.forEach(loader => {
          cefr += parseInt(loader.cefr_rate, 10)
        })
        this.cefrRate[index] = Math.round(cefr / loaders.length)
      })
    },

    showRubric(student) {
      this.viewingRubricsOfStudent = student
    },
    getClassRubrics() {
      this.isProcessing = true
      useJwt
        .getClassRubrics(this.classId)
        .then(res => {
          this.activeClassRubrics = res.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    showDeleteModalForStudent(id) {
      this.studentBeingDeleted = id
    },
    removeStudentFromAClass(bvModalEvt) {
      this.isProcessingDelete = true
      bvModalEvt.preventDefault()

      useJwt
        .removeStudentFromClass(this.classId, this.studentBeingDeleted)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.studentBeingDeleted = null
          this.getClassRoomStat()
          this.getStudentsOfClassRooms()
          this.isProcessingDelete = false
        })
    },
    showClassStats() {
      if (this.$store.state.appConfig.layout.ui === 'modern') {
        this.$router.push({
          name: `${this.self.usertype}-class`,
          params: {
            id: this.classId,
            courseId: 'course',
            reporting: 'reporting',
          },
        })
        return
      }
      if (this.room.type !== 'custom') {
        this.$router.push({
          name: `${this.self.usertype}-class`,
          params: { id: this.classId },
          query:
            this.self.id === this.viewingClassesOfId
              ? {}
              : { user_id: this.viewingClassesOfId },
        })
      } else {
        this.$router.push({
          name: `${this.self.usertype}-course`,
          params: { id: this.classId },
        })
      }
    },
    showNotification() {
      this.$router.push({
        name: 'send-notifications',
        query: {
          classId: this.classId,
        },
      })
    },
    viewLessons(id, classId, type) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
        query: { type, group: Number(this.$route.params.courseId) === Number(id) ? this.$route.query.group : null },
      })
    },
    searchStudent(value) {
      this.searchInput = value
      if (this.fetchingTimeout) clearTimeout(this.fetchingTimeout)
      this.fetchingTimeout = setTimeout(() => {
        useApollo
          .searchStudents(this.searchInput, parseInt(this.classId, 10))
          .then(res => {
            this.searchUser = res.data.searchUser
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
      }, 500)
    },
    getStudentsOfClassRooms() {
      this.getStudentsForClass()
      this.getStudentClassCefr()
    },
    showAttendance(student) {
      const name = this.isAStudent
        ? 'student-diary'
        : `${this.self.usertype}-view-attendance`
      this.$router.push({ name, params: { id: student.id }, query: { classId: this.classId } })
    },
    viewClassCalendar(id) {
      this.$router.push({
        name: `${this.self.usertype}-class-calendar`,
        params: { id, classId: id },
      })
    },
    getStudentsForClass() {
      useJwt.getStudentsForClass(this.classId, { page: this.page, limit: 60, search: this.searchStudentInput }).then(response => {
        if (this.page === 1) this.students = response.data.data || []
        else {
          const data = response.data.data.data ? response.data.data.data : []
          this.students.data = [...this.students.data, ...data]
        }
      })
      // }
    },
    getColorForLevel(lvl) {
      return getColorForLevel(lvl) || '#c72f43'
    },
    getLevelFromIndex(lvl) {
      return getLevelFromScore(lvl)
    },
    getPoints(index) {
      let points = 0
      if (this.room.students) {
        this.room.students[index].loaders.forEach(loader => {
          points += loader?.points
        })
      }

      return points
    },
    setClassRoomSetting(settings) {
      this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: this.selfStudyClassRoom })
    },
    getClassRoomStat() {
      this.isFetchingStudentStats = true
      useJwt
        .getClassRoomStat(this.classId, {
          params: {
            studentId: this.filterStudent,
          },
        })
        .then(response => {
          const {
            // students,
            pairs, courses, classroom, settings, isSelfStudyMode, overAllCourseCompletion, followUpCourses, hasVocabLesson,
          } = response.data.data
          // let uniquePair = []
          pairs.forEach(pair => {
            const array = JSON.parse(pair)
            this.uniquePair = [...new Set([...this.uniquePair, ...array])]
          })

          this.selfStudyClassRoom = isSelfStudyMode ? 1 : 0
          // this.setClassRoomSetting(settings)

          // const requiredFormat = students.map(student => ({
          //   avatar: student.avatar,
          //   fullname: `${student.firstname} ${student.lastname}`,
          //   id: student.student_id,
          //   isOnline: student.isOnline,
          //   totalPoints: student.total_points,
          //   role: student.role,
          //   conductor: student.conductor,
          //   read: student.read,
          //   answer: student.answer,
          //   totalFirstLessonDone: student.total_first_lesson_done,
          //   hasPairs: this.uniquePair.includes(Number(student.student_id)),
          //   lang_level: student.lang_level,
          //   live_link: student.live_link,
          //   totalProcess:
          //     Number(student.lesson_completed) > 100
          //       ? 100
          //       : Number(student.lesson_completed),
          //   currentProgress:
          //     Number(student.lesson_completion_upto_today) > 100
          //       ? 100
          //       : Number(student.lesson_completion_upto_today),
          //   extraProgress:
          //     Number(student.lesson_completed) > 100
          //       ? Number(student.lesson_completed) - 100
          //       : null,
          // }))
          // requiredFormat.sort((a, b) => b.totalPoints - a.totalPoints)
          // this.studentWithStat = requiredFormat
          // this.filteredStudentsLists = requiredFormat
          this.courseByProgress = [
            {
              title: i18n.tc('courses-module.all-courses'),
              data: courses,
            },
            ...(this.inProgressCompletedTabVisbile ? [
              {
                title: i18n.tc('courses-module.in_progress'),
                data: courses.filter(item => item.lesson_completion < 100),
              },
              {
                title: i18n.tc('courses-module.completed'),
                data: courses.filter(item => item.lesson_completion >= 100),
              },
            ] : []),
          ]
          // const privateLink = requiredFormat.find(i => +i.id === +this.self.id)?.live_link
          // classroom.link = privateLink || classroom.link
          this.room = classroom
          this.courses = courses
          const schoolData = {
            id: this.room.school.id,
            firstname: this.room.school.firstname,
            lastname: this.room.school.lastname,
            // font: this.room.school.meta[0].value,
          }
          const metas = this.room?.school?.meta ?? []
          metas.map(meta => {
            schoolData[meta.key] = meta.value
            return schoolData
          }, {})
          const settingValue = settings.find(option => option.setting_name === 'font')

          if (settingValue) {
            const selectedFont = fonts.find(option => option.value === settingValue.value)
            schoolData.font = selectedFont.text
          }

          localStorage.setItem('schoolData', JSON.stringify(schoolData))

          this.overAllCourseCompletion = parseFloat(overAllCourseCompletion)
          this.followUpCourses = followUpCourses
          this.hasVocabLesson = hasVocabLesson
        }).finally(() => {
          this.isFetchingStudentStats = false
          this.getStudentsListOfClassroom()
        })
    },
    handleStudentListScroll(e) {
      // console.log('scrolling', this.studentCurrentPage, this.studentLastPage)
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      // console.log(scrollTop, offsetHeight, scrollHeight)
      // console.log('this.filterStudent', scrollHeight - (scrollTop + offsetHeight))
      if (scrollTop < 3) {
        this.studentCurrentPage = 1
      } else if ((scrollHeight - (scrollTop + offsetHeight) < 5) && (this.studentLastPage >= this.studentCurrentPage)) {
        this.studentCurrentPage += 1
      }
      if (this.studentCurrentPage > this.studentLastPage) {
        this.studentCurrentPage = this.studentLastPage
      }
      console.log('after scroll', this.studentCurrentPage)
      if (!this.filterStudent) { this.getStudentsListOfClassroom() }
    },
    getStudentsListOfClassroom() {
      const params = {
        page: this.studentCurrentPage,
        limit: this.studentLimit,
      }
      useJwt.getStudentsListOfClassroom(this.classId, { params })
        .then(res => {
          this.studentLastPage = res.data.data.last_page
          this.totalStudents = res.data.data.total
          const students = res.data.data.data
          const requiredFormat = students.map(student => ({
            avatar: student.avatar,
            fullname: `${student.firstname} ${student.lastname}`,
            id: student.student_id,
            isOnline: student.isOnline,
            totalPoints: student.total_points,
            role: student.role,
            conductor: student.conductor,
            read: student.read,
            answer: student.answer,
            totalFirstLessonDone: student.total_first_lesson_done,
            hasPairs: this.uniquePair.includes(Number(student.student_id)),
            lang_level: student.lang_level,
            live_link: student.live_link,
            totalProcess:
              Number(student.lesson_completed) > 100
                ? 100
                : Number(student.lesson_completed),
            currentProgress:
              Number(student.lesson_completion_upto_today) > 100
                ? 100
                : Number(student.lesson_completion_upto_today),
            extraProgress:
              Number(student.lesson_completed) > 100
                ? Number(student.lesson_completed) - 100
                : null,
          }))
          requiredFormat.sort((a, b) => b.totalPoints - a.totalPoints)
          // console.log(requiredFormat)
          this.studentWithStat = requiredFormat
          this.filteredStudentsLists = requiredFormat
          // console.log('studentWithStat', this.studentWithStat)
          const privateLink = requiredFormat.find(i => +i.id === +this.self.id)?.live_link
          this.room.link = privateLink || this.room.link
        })
    },
    showRewardsModal(id) {
      if (this.isAStudent && id !== this.self.id) return
      this.studentId = id
      this.$nextTick(() => { this.$bvModal.show('classroom-rewards') })
    },
    showMoveStudentModal(student) {
      this.studentBeingMoved = student
      this.$nextTick(() => { this.$bvModal.show('move-student') })
    },
    updateToPaid(type) {
      this.isUpdatingToPaid = true
      useJwt.reservationPaidUpgrade(this.room.reservation_id, {
        type,
      }).then(response => {
        if (response.data.data.payment?.checkoutLink) {
          window.open(response.data.data.payment?.checkoutLink, '_self')
        }
        this.showSuccessMessage(response)
      }).finally(() => {
        this.isUpdatingToPaid = false
      })
    },
    requestTeacher() {
      if (this.room.reservation_id) {
        this.showSchedules = true
      } else {
        this.requestProcessing = true
        useJwt.requestTeacher({ classId: this.classId }).then(response => {
          this.showSuccessMessage(response)
        }).finally(() => { this.requestProcessing = false }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    applyAfterCssForCourseProgress(course) {
      const style = `<style>#course-btn-${course.course_id}:after{
          width: ${course.lesson_completion > 100 ? course.lesson_completion : course.lesson_completion}%;
        }</style>`
      return style
    },
    fetchMoreStudents(page) {
      this.page = page
      this.getStudentsForClass()
    },
    searchStudents(input) {
      this.searchStudentInput = input
      if (input) {
        this.page = 1
      }
      this.getStudentsForClass()
    },

    gotoLessonPlanner() {
      this.$router.push({
        name: 'teacher-lesson-planner',
        params: { classId: this.classId },
      })
    },

    updateSelfStudyClassRoom() {
      useJwt.updateClassroomSelfStudy(this.classId, { enable: this.selfStudyClassRoom }).then(res => {
        this.showSuccessMessage(res)
        this.getClassRoomStat()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        const generalSettings = res.data?.data
        const districtSettings = res.data?.district_settings
        this.hideFullCourseProgress = generalSettings.find(item => item.setting_name === 'full_course_progress')?.value || 0
        this.hideCurrentProgress = generalSettings.find(item => item.setting_name === 'current_progress')?.value || 0
        this.hideSkillChart = generalSettings.find(item => item.setting_name === 'skill_chart')?.value || 0
        this.hidePoint = generalSettings.find(item => item.setting_name === 'point')?.value || 0
        this.hideLanguageLevel = generalSettings.find(item => item.setting_name === 'language_level')?.value || 0
        this.hideBadge = generalSettings.find(item => item.setting_name === 'badge')?.value || 0
        this.singleDoubleCourseCompletionBar = +generalSettings.find(item => item.setting_name === 'single_double_course_completion')?.value
        if (!this.singleDoubleCourseCompletionBar) this.singleDoubleCourseCompletionBar = +res.data.district_settings.find(item => item.setting_name === 'single_double_course_completion')?.value || 0
        this.defaultIndividualReport = generalSettings.find(item => item.setting_name === 'default_individual_report')?.value
        if (!this.defaultIndividualReport) this.defaultIndividualReport = +res.data.district_settings.find(item => item.setting_name === 'default_individual_report')?.value || 0

        // check for district and school level settings
        // student skill chart
        const schoolStudentSkillChart = +generalSettings.find(item => item.setting_name === 'student_skill_chart')?.value || 0
        const districtStudentSkillChart = +districtSettings.find(item => item.setting_name === 'student_skill_chart')?.value || 0
        this.hideStudentSkilChart = districtStudentSkillChart === 1 || schoolStudentSkillChart === 1 ? 1 : 0

        // student diary
        const schoolStudentDiary = +generalSettings.find(item => item.setting_name === 'student_diary')?.value || 0
        const districtStudentDiary = +districtSettings.find(item => item.setting_name === 'student_diary')?.value || 0
        this.hideStudentDiary = schoolStudentDiary === 1 || districtStudentDiary === 1 ? 1 : 0

        // student rubrics
        const schoolStudentRubrics = +generalSettings.find(item => item.setting_name === 'student_rubrics')?.value || 0
        const districtStudentRubrics = +districtSettings.find(item => item.setting_name === 'student_rubrics')?.value || 0
        this.hideStudentRubrics = schoolStudentRubrics === 1 || districtStudentRubrics === 1 ? 1 : 0

        // student notes
        const schoolStudentNotes = +generalSettings.find(item => item.setting_name === 'student_notes')?.value || 0
        const districtStudentNotes = +districtSettings.find(item => item.setting_name === 'student_notes')?.value || 0
        this.hideStudentNotes = schoolStudentNotes === 1 || districtStudentNotes === 1 ? 1 : 0

        // student_individual_report
        const schoolStudentIndividualReport = +generalSettings.find(item => item.setting_name === 'student_individual_report')?.value || 0
        const districtStudentIndividualReport = +districtSettings.find(item => item.setting_name === 'student_individual_report')?.value || 0
        this.hideStudentIndividualReport = schoolStudentIndividualReport === 1 || districtStudentIndividualReport === 1 ? 1 : 0

        // streak persistence
        const schoolStreakPersistence = +generalSettings.find(item => item.setting_name === 'streak_persistence')?.value || 0
        const districtStreakPersistence = +districtSettings.find(item => item.setting_name === 'streak_persistence')?.value || 0
        this.hideBadgesInfo = schoolStreakPersistence === 1 || districtStreakPersistence === 1 ? 1 : 0

        // student cards
        const schoolStudentCard = +generalSettings.find(item => item.setting_name === 'student_card')?.value || 0
        const districtStudentCard = +districtSettings.find(item => item.setting_name === 'student_card')?.value || 0
        this.hideStudentCards = (this.isAStudent && (schoolStudentCard === 1 || districtStudentCard === 1)) ? 1 : 0

        // student point
        const schoolStudentPoints = +generalSettings.find(item => item.setting_name === 'student_point')?.value || 0
        const districtStudentPoints = +districtSettings.find(item => item.setting_name === 'student_point')?.value || 0
        this.hideStudentPoint = (schoolStudentPoints === 1 || districtStudentPoints === 1) ? 1 : 0

        // student point detail
        const schoolStudentPointDetail = +generalSettings.find(item => item.setting_name === 'student_point_popup')?.value || 0
        const districtStudentPointDetail = +districtSettings.find(item => item.setting_name === 'student_point_popup')?.value || 0
        this.hideStudentPointDetail = (schoolStudentPointDetail === 1 || districtStudentPointDetail === 1) ? 1 : 0

        // teacher
        const schoolTeacherSetting = +generalSettings.find(item => item.setting_name === 'teacher')?.value || 0
        const districtTeacherSetting = +districtSettings.find(item => item.setting_name === 'teacher')?.value || 0
        this.hideTeacher = (schoolTeacherSetting === 1 || districtTeacherSetting === 1) ? 1 : 0

        // co-teacher
        const schoolCoTeacherSetting = +generalSettings.find(item => item.setting_name === 'co_teachers')?.value || 0
        const districtCoTeacherSetting = +districtSettings.find(item => item.setting_name === 'co_teachers')?.value || 0
        this.hideCoTeacher = (schoolCoTeacherSetting === 1 || districtCoTeacherSetting === 1) ? 1 : 0

        // owner
        const schoolOwnerSetting = +generalSettings.find(item => item.setting_name === 'owner')?.value || 0
        const districtOwnerSetting = +districtSettings.find(item => item.setting_name === 'owner')?.value || 0
        this.hideOwner = (schoolOwnerSetting === 1 || districtOwnerSetting === 1) ? 1 : 0

        // school
        const schoolSchoolNameSetting = +generalSettings.find(item => item.setting_name === 'school')?.value || 0
        const districtSchoolNameSetting = +districtSettings.find(item => item.setting_name === 'school')?.value || 0
        this.hideSchool = (schoolSchoolNameSetting === 1 || districtSchoolNameSetting === 1) ? 1 : 0

        // classroom list button
        const schoolClassroomListSetting = +generalSettings.find(item => item.setting_name === 'classroom_list')?.value || 0
        const districtClassroomListSetting = +districtSettings.find(item => item.setting_name === 'classroom_list')?.value || 0
        this.hideClassroomListButton = (schoolClassroomListSetting === 1 || districtClassroomListSetting === 1) ? 1 : 0

        // Class Test
        const schoolClassTestSetting = +generalSettings.find(item => item.setting_name === 'class_test')?.value || 0
        const districtClassTestSetting = +districtSettings.find(item => item.setting_name === 'class_test')?.value || 0
        this.hideClassTest = (schoolClassTestSetting === 1 || districtClassTestSetting === 1) ? 1 : 0

        // view essay
        const schoolViewEssaySetting = +generalSettings.find(item => item.setting_name === 'view_essay')?.value || 0
        const districtViewEssaySetting = +districtSettings.find(item => item.setting_name === 'view_essay')?.value || 0
        this.hideViewEssay = (schoolViewEssaySetting === 1 || districtViewEssaySetting === 1) ? 1 : 0

        // show script
        const schoolShowScriptSetting = +generalSettings.find(item => item.setting_name === 'show_script')?.value || 0
        const districtShowScriptSetting = +districtSettings.find(item => item.setting_name === 'show_script')?.value || 0
        this.hideShowScript = (schoolShowScriptSetting === 1 || districtShowScriptSetting === 1) ? 1 : 0

        // language_summary_chart
        const schoolLanguageSummarySetting = +generalSettings.find(item => item.setting_name === 'language_summary_chart')?.value || 0
        const districtLanguageSummarySetting = +districtSettings.find(item => item.setting_name === 'language_summary_chart')?.value || 0
        this.hideLanguageSummaryChart = (schoolLanguageSummarySetting === 1 || districtLanguageSummarySetting === 1) ? 1 : 0

        // skill_summary_chart
        const schoolSkillSummarySetting = +generalSettings.find(item => item.setting_name === 'skill_summary_chart')?.value || 0
        const districtSkillSummarySetting = +districtSettings.find(item => item.setting_name === 'skill_summary_chart')?.value || 0
        this.hideSkillSummaryChart = (schoolSkillSummarySetting === 1 || districtSkillSummarySetting === 1) ? 1 : 0

        // skill_summary_chart
        const schoolStudentRoleSetting = +generalSettings.find(item => item.setting_name === 'student_role_chart')?.value || 0
        const districtStudentRoleSetting = +districtSettings.find(item => item.setting_name === 'student_role_chart')?.value || 0
        this.hideStudentRoleChart = (schoolStudentRoleSetting === 1 || districtStudentRoleSetting === 1) ? 1 : 0

        // student_note_chart
        const schoolStudentNoteSetting = +generalSettings.find(item => item.setting_name === 'student_note_chart')?.value || 0
        const districtStudentNoteSetting = +districtSettings.find(item => item.setting_name === 'student_note_chart')?.value || 0
        this.hideStudentNoteChart = (schoolStudentNoteSetting === 1 || districtStudentNoteSetting === 1) ? 1 : 0

        // student_game_report
        const schoolStudentGameSetting = +generalSettings.find(item => item.setting_name === 'student_game_report')?.value || 0
        const districtStudentGameSetting = +districtSettings.find(item => item.setting_name === 'student_game_report')?.value || 0
        this.hideStudentGameReport = (schoolStudentGameSetting === 1 || districtStudentGameSetting === 1) ? 1 : 0

        let settings = engagementSettings(['game', 'attention'], generalSettings, districtSettings)

        let avatarSetting = districtSettings.find(item => item.setting_name === 'avatar')
        if (Number(avatarSetting?.value)) {
          avatarSetting = generalSettings.find(item => item.setting_name === 'avatar')
        }

        if (avatarSetting) {
          settings = [
            ...settings,
            avatarSetting,
          ]
        }

        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      })
    },
    addMarginBottom(studentId) {
      if ((studentId === this.self.id) && (this.hideStudentSkilChart === 0 || this.hideStudentDiary === 0 || this.hideStudentRubrics === 0 || this.hideStudentNotes === 0 || this.hideStudentIndividualReport === 0)) {
        return 'mb-1'
      }
      return 'mb-0'
    },
  },

}
</script>
<style scoped lang="scss">
.badge h4 {
  font-size: 14px !important;
}

.top-badge,
.first-badge {
  width: 25px;
  height: 25px;
}
.student-details-card {
  /*min-height: 255px !important;*/
}
.student-details-card .card-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.badge-row {
  padding-top: 5px;
  min-height: 25px;
}
.btn-purple {
  background-color: #906acc !important;
  border: #906acc !important;
}
.btn-dark-blue {
  background-color: #8483d2 !important;
  border: #9695c9 !important;
}
.btn-add-rubric {
  background-color: #28c76f !important;
  border: #28c76f !important;
}
.btn-attendance {
  background-color: #d24a8d !important;
  border: #28c76f !important;
}
.btn-student-scoreboard {
  background-color: #5b60c7 !important;
  border: #28c76f !important;
}

.class-stats .title {
  margin-bottom: 4px;
}
.class-stats {
  max-width: 148px;
}
.badge-container {
  display: block;
}
.badge-icon {
  margin: 0 4px;
}
.info-icon {
  position: absolute;
  top: 0;
}
.dark-layout .info-icon {
  color: #ffe699;
  fill: #333;
}

/* .nav-item {
  border: 1px solid black;
  height: 30px;
  margin: 2px;
} */

@media screen and (max-width: 576px) {
  .top-row .col {
    padding: 0 !important;
  }
  .top-row .h2 {
    font-size: 18px !important;
  }
  .dates {
    justify-content: space-between;
  }
  .row-2 {
    flex-flow: column-reverse;
  }
}

@media screen and (max-width: 912px) and (min-width: 540px) {
  .stats-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row;
  }
}

@media screen and (max-width: 1200px) {
  .stats-container {
    justify-content: flex-end;
    gap: 48px;
  }
}

.ribbon {
  position: absolute;
  margin-left: 10px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}
.ribbon span {
  font-size: 0.7rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 26px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  text-indent: -40px;
  right: -29px;
  right: -60px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.student-view-card .card-body {
  padding: 0px !important;
}
.stats-container-left {
  background-size: contain;
  background-repeat: no-repeat;
}

.badge-icon {
  height: 40px;
  width: 40px;
}

.p-t-1 {
  padding-top: 10px;
}
.teachers {
  margin-right: 4px;
}
.progress-btn {
  /* width:200px; */
  height:50px;
  border:1px solid black;
  position:relative;
}
.progress-btn:after {
  content:'\A';
  position:absolute;
  background: #6ad08b40;
  top:0; bottom:0;
  left:0;
  transition:all 2s;
  -webkit-transition:all 2s;
}
.clickable-div:hover {
  border: 1.2px solid #0041ff;
}
.action-icon {
  margin-left: 8px;
  cursor: pointer;
  padding: 5px;
  border-radius: 7px ;
}
.action-icon:hover {
  background: rgba(40,199,111,.12);
}

.vertical-progress {
  height: 200px; /* Adjust the height as needed */
  transform: rotate(-90deg);
  transform-origin: top left;
}

.vertical-progress .progress-bar {
  width: 100%;
}

.gradient-background {
  background: linear-gradient(to right, #9ecdb0, #d2f5bd);
}

.dark-layout .gradient-background {
  background: #283046;
}
.scroll-content {
  max-height: 522px;
}
</style>
